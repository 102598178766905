#feat-card-container {
    padding: 17.2vw 72px 0 95px;
}

#feat-card-bg {
    position: absolute;
    left: -5.8vw;
    z-index: -1;
    top: 75px;
    width: 100vw;
}

.feature-card {
    width: 36.8vw;
    height: 36.8vw;
    padding: 3.12vw;
}

.feature-card .img {
    width: 7.65vw;
}

.feature-card .title{
    width: 24.2vw;
    padding-top: 5%;
    white-space: pre-line;
    font-weight: 800;
    font-size: 3.6vw;
    line-height: 3.6vw;
    text-transform: uppercase;
}

.feature-card .content{
    width: 28.9vw;
    padding-top: 5%;
    
    font-weight: 600;
    font-size: 1.48vw;
    line-height: 1.79vw;
}

.feature-card#card-1{
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('https://dteklg4bowqr.cloudfront.net/nft/website/img_card_green.webp');
}

.feature-card#card-2{
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('https://dteklg4bowqr.cloudfront.net/nft/website/img_card_yellow.webp');
}

#feat-card-cats {
    display: block;
    margin: auto;
    padding-top: 50px;
}

@media only screen and (max-width: 576px){
    #feat-card-container {
        padding: 13.2vw 0 0 0;
    }
    
    .feature-card {
        width: 83vw;
        height: 83vw;
        padding: 20px;
        margin: auto;
    }
    
    .feature-card .img {
        width: 21vw;
    }

    .feature-card .title{
        width: 72vw;
        font-size: 24px;
        line-height: 24px;
    }
    
    .feature-card .content{
        width: 72vw;
        font-size: 4vw;
        line-height: 4vw;
    }

    .feature-card#card-2{
        margin-top: 20px
    }

    #feat-card-cats {
        width: 100%;
    }
}

@media only screen and (min-width: 1440px){
    .feature-card {
        width: 470px;
        height: 470px;
        padding: 40px;
    }

    .feature-card .img {
        width: auto;
    }

    .feature-card .title{
        width: 292px;
        font-size: 46px;
        line-height: 46px;
    }
    
    .feature-card .content{
        width: 370px;
        font-size: 19px;
        line-height: 23px;
    }
}