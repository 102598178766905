#landing-container {
    padding: 4.5vh 4.6vw 0!important;
}

#landing-body {
    padding-top: 20.5vh;
    color: #000000;
}

@media only screen and (max-width: 576px){
    #landing-body {
        padding-top: 300px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 991px){
    #landing-body {
        padding-top: 600px;
    }
}

#landing-title {
    white-space: pre-line;
    font-weight: 800;
    font-size: 8vw;
    line-height: 98%;
    text-transform: uppercase;
}

#landing-content {
    width: 30vw;
    padding-top: 8.24vh;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

@media only screen and (max-width: 991px){
    #landing-content {
        width: 100%;
    }
}

@media only screen and (min-width: 1440px){
    #landing-content {
        font-size: 25px;
        line-height: 35px;
    }
}

#landing-img-1 {
    position: absolute;
    right: -4.6vw;
    top: 6vh;
    z-index: -1;
}

#cookie-consent span {
    text-decoration: underline;
    color: white;
    cursor: pointer;
}

.close-icn {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
    cursor: pointer;
}