#coming-soon-container {
    min-height: 100vh;
    padding: 46px 46px;
    background: linear-gradient(14.93deg, #8DFF33 -3.18%, rgba(0, 163, 255, 0.3) 111.06%), #FFFFFF;
}

.fade-in-comp {
    animation: fadeIn 3s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#close-icn {
    float: right;
}

#cs-title {
    font-weight: 800;
    font-size: 106px;
    line-height: 104px;
    text-transform: uppercase;
    padding-top: 13vw;
}

#cs-content {
    padding-top: 50px;
    font-weight: 800;
    font-size: 76px;
    line-height: 84px;
    text-transform: uppercase;
    white-space: pre-line;
}

@media only screen and (max-width: 576px){ 

    .close-icn img{
        width: 10vw;
    }

    #coming-soon-container {
        padding: 40px 20px;
    }

    #cs-title {
        font-size: 11vw;
        line-height: 11vw;
    }

    #cs-content {
        font-size: 8vw;
        line-height: 13vw;
    }
}