#faq-bg {
    background-color: #F2F2F2;
    padding: 130px 100px 136px 85px; 
}

#faq-title {
    font-weight: 800;
    font-size: 76px;
    line-height: 46px;

    text-transform: uppercase;
}

#faq-container {
    padding-top: 33px;
}

[id^=question] {
    padding-top: 35px;
    font-weight: 800;
    font-size: 32px;
    line-height: 46px;

    text-transform: uppercase;
    cursor: pointer;
}

.answer {
    width: 70vw;
    padding-top: 30px;

    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

}

.expand {
    float: right;
}

.faq-divider {
    margin-top: 30px;
    width: 100%;
    height: 4px;
    background-color: #D9D9D9;
}

#tnc {
    padding-top: 70px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    text-align: right;
    text-decoration-line: underline;
}

#tnc span {
    cursor: pointer;
}

.table-header {
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    background: #D9D9D9 !important;
}

@media only screen and (max-width: 768px){
    #faq-bg {
        padding: 40px 15px
    }

    #faq-title {
        font-size: 50px;
    }

    [id^=question] {
        padding-top: 20px;
        font-size: 25px;
        line-height: 40px;
    }

    .answer {
        width: auto;
    }
}