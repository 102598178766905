html {
    max-width: 100%;
    overflow-x: hidden;
}

body {
    font-family: 'Barlow', 'Arial';
    font-style: normal;
    color: #000000;
}

/* * {
    outline: solid 1px red;
} */

.fw-900 {
    font-weight: 900;
}