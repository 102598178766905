#logo {
    
}

#nav-collapse {
    justify-content: flex-end;
}

#toggle {
    border: none;
    padding: 0;
}

#toggle:focus {
    box-shadow: none;
}

.nav-link {
    padding: 0 0.78vw !important;
    font-weight: 800 !important; 
    line-height: 29px;

    color: #000000;

}

@media only screen and (max-width: 991px){
    .nav-link {
        text-align: center;
        padding: 10px 0 !important;
    }
}

.hr {
    width: 19px;
    height: 4px;
    background: #000000;
    align-self: center;
}

@media only screen and (max-width: 991px){
    .hr {
        display: none;
    }
}

.dropdown-menu.show {
    text-align: center;
    border: none;
    min-width: auto;
}

.dropdown-divider {
    margin: auto !important;
    width: 55px;
}

.dropdown-item {
    padding-left: 11px !important;
}